import pageLoaded from './pageLoaded'
import { login, logout, signup, forgotPassword, resetPassword } from './login'
import updateSettings from './updateSettings'
import { bookTour } from './stripe'
import displayMap from './leaflet'
import * as accountMenu from './accountMenu'
import * as query from './query'

pageLoaded.then(() => {
	const loginForm = document.querySelector('.form--login')
	if (loginForm) {
		loginForm.addEventListener('submit', (e) => {
			e.preventDefault()
			login(new FormData(loginForm))
		})
	}

	const signupForm = document.querySelector('.form--signup')
	if (signupForm) {
		signupForm.addEventListener('submit', (e) => {
			e.preventDefault()
			signup(new FormData(signupForm))
		})
	}

	const forgotPasswordForm = document.querySelector('.form-forgotPassword')
	if (forgotPasswordForm) {
		forgotPasswordForm.addEventListener('submit', (e) => {
			e.preventDefault()
			forgotPassword(new FormData(forgotPasswordForm))
		})
	}

	const resetPasswordForm = document.querySelector('.form-resetPassword')
	if (resetPasswordForm) {
		resetPasswordForm.addEventListener('submit', (e) => {
			e.preventDefault()
			resetPassword(new FormData(resetPasswordForm))
		})
	}

	const logoutButton = document.querySelector('.logout-button')
	if (logoutButton) {
		logoutButton.addEventListener('click', (e) => {
			e.preventDefault()
			logout()
		})
	}

	const accountMenuToggle = document.querySelector('.account-button')
	if (accountMenuToggle) {
		accountMenuToggle.addEventListener('click', (e) => {
			e.preventDefault()
			accountMenu.toggle(accountMenuToggle)
		})
	}

	if (query.sortToggle) {
		query.sortToggle.addEventListener('click', (e) => {
			e.preventDefault()
			query.toggleSort(query.sortToggle)
		})
	}

	if (query.filterToggle) {
		query.filterToggle.addEventListener('click', (e) => {
			e.preventDefault()
			query.toggleFilter(query.filterToggle)
		})
	}

	const header = document.querySelector('header')
	document.addEventListener('click', function (e) {
		if (!accountMenu.element.contains(e.target) && !header.contains(e.target)) {
			accountMenu.hide(accountMenuToggle)
		}
	})

	const userDataForm = document.querySelector('.form-user-data')
	if (userDataForm) {
		userDataForm.addEventListener('submit', async (e) => {
			e.preventDefault()
			await updateSettings(new FormData(userDataForm), 'data')
			document.querySelector('.user-img').src = userPhoto.src
		})

		const photoInput = document.getElementById('photo')
		const userPhoto = document.querySelector('.photo-upload img')
		photoInput.addEventListener('change', async (e) => {
			const photo = e.target.files[0]

			const reader = new FileReader()
			reader.onloadend = () => {
				userPhoto.src = reader.result
			}
			reader.readAsDataURL(photo)
		})
	}

	const userPasswordForm = document.querySelector('.form-user-password')
	if (userPasswordForm) {
		userPasswordForm.addEventListener('submit', async (e) => {
			e.preventDefault()
			document.querySelector('.btn--save-password').textContent = 'Updating...'
			await updateSettings(new FormData(userPasswordForm), 'password')
			userPasswordForm.reset()
			document.querySelector('.btn--save-password').textContent = 'Save password'
		})
	}

	const bookTourButtons = document.querySelectorAll('.book-tour')
	if (bookTourButtons.length) {
		bookTourButtons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault()
				e.target.textContent = 'Processing...'
				bookTour(e.target.dataset.tourId)
			})
		})
	}

	const map = document.getElementById('map')
	if (map) {
		const locations = JSON.parse(document.getElementById('map').dataset.locations)
		displayMap(locations)
	}

	const slider = document.querySelector('.reviews-container')
	if (slider) {
		let isDown = false
		let startX
		let scrollLeft

		slider.addEventListener('mousedown', (e) => {
			isDown = true
			slider.classList.add('active')
			startX = e.pageX - slider.offsetLeft
			scrollLeft = slider.scrollLeft
		})
		slider.addEventListener('mouseleave', () => {
			isDown = false
			slider.classList.remove('active')
		})
		slider.addEventListener('mouseup', () => {
			isDown = false
			slider.classList.remove('active')
		})
		slider.addEventListener('mousemove', (e) => {
			if (!isDown) return
			e.preventDefault()
			const x = e.pageX - slider.offsetLeft
			const walk = (x - startX) * 1.75
			slider.scrollLeft = scrollLeft - walk
		})
	}
})
