import pageLoaded from './pageLoaded'

let sortToggle, filterToggle, queryContainer, sortContainer, filterContainer
pageLoaded.then(() => {
	sortToggle = document.querySelector('button.sort')
	filterToggle = document.querySelector('button.filter')
	queryContainer = document.getElementById('query-container')
	sortContainer = document.getElementById('sort')
	if (sortContainer) {
		sortContainer.addEventListener('change', (e) => {
			Array.from(sortContainer.children).forEach((element) => {
				element.children[0].checked ? element.classList.add('active') : element.classList.remove('active')
			})
			var searchParams = new URLSearchParams(window.location.search)
			searchParams.set('sort', e.target.value)
			const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
			history.pushState(null, '', newRelativePathQuery)
			updateTourCards()
		})
		if (!CSS.supports('selector(:has(*))')) {
			let mouseDown = false
			sortContainer.addEventListener('mousedown', () => (mouseDown = true))
			sortContainer.addEventListener('mouseup', () => setTimeout(() => (mouseDown = false), 0))
			sortContainer.addEventListener('change', (e) => {
				if (mouseDown) document.activeElement.blur()
			})
		}
	}
	filterContainer = document.getElementById('filter')
	if (filterContainer) {
		filterContainer.addEventListener('change', (e) => {
			Array.from(filterContainer.children).forEach((element) => {
				element.children[0].checked ? element.classList.add('active') : element.classList.remove('active')
			})
			var searchParams = new URLSearchParams(window.location.search)
			// searchParams.set('category', e.target.value)
			searchParams.delete('category')
			Array.from(filterContainer.children).forEach((element) => {
				element.children[0].checked && searchParams.append('category', element.children[0].value)
			})
			const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
			history.pushState(null, '', newRelativePathQuery)
			updateTourCards()
		})
		if (!CSS.supports('selector(:has(*))')) {
			let mouseDown = false
			filterContainer.addEventListener('mousedown', () => (mouseDown = true))
			filterContainer.addEventListener('mouseup', () => setTimeout(() => (mouseDown = false), 0))
			filterContainer.addEventListener('change', (e) => {
				if (mouseDown) document.activeElement.blur()
			})
		}
	}
	window.addEventListener('popstate', () => {
		updateTourCards()
	})
})

const showSort = () => {
	delete queryContainer.dataset.hidden
	delete sortContainer.dataset.hidden
	sortToggle.ariaExpanded = true
	sortToggle.ariaLabel = sortToggle.dataset.openedLabel
	sortToggle.dataset.pressed = true
}

const hideSort = () => {
	queryContainer.dataset.hidden = true
	sortContainer.dataset.hidden = true
	sortToggle.ariaExpanded = false
	sortToggle.ariaLabel = sortToggle.dataset.closedLabel
	delete sortToggle.dataset.pressed
}

const toggleSort = () => {
	if (sortContainer.dataset.hidden) {
		hideFilter()
		showSort()
	} else {
		hideSort()
	}
}

const showFilter = () => {
	delete queryContainer.dataset.hidden
	delete filterContainer.dataset.hidden
	filterToggle.ariaExpanded = true
	filterToggle.ariaLabel = filterToggle.dataset.openedLabel
	filterToggle.dataset.pressed = true
}

const hideFilter = () => {
	queryContainer.dataset.hidden = true
	filterContainer.dataset.hidden = true
	filterToggle.ariaExpanded = false
	filterToggle.ariaLabel = filterToggle.dataset.closedLabel
	delete filterToggle.dataset.pressed
}

const toggleFilter = () => {
	if (filterContainer.dataset.hidden) {
		hideSort()
		showFilter()
	} else {
		hideFilter()
	}
}

const updateTourCards = async () => {
	const cardContainer = document.querySelector('.card-container')
	const fadeIn = { transform: ['translateY(5px)', 'translateY(0px)'], opacity: [0, 1] }
	const options = { duration: 250, fill: 'forwards', easing: 'ease' }
	cardContainer.animate(fadeIn, { ...options, direction: 'reverse' })
	const timer = new Promise((resolve) => setTimeout(() => resolve(), 300))
	const response = await fetch(window.location.href)
	const data = await response.text()
	const parsedHtml = new DOMParser().parseFromString(data, 'text/html')
	await timer
	cardContainer.innerHTML = parsedHtml.querySelector('.card-container').innerHTML
	document.querySelector('.overview-header h1').innerHTML = parsedHtml.querySelector('.overview-header h1').innerHTML
	document.querySelector('#sort').innerHTML = parsedHtml.querySelector('#sort').innerHTML
	document.querySelector('#filter').innerHTML = parsedHtml.querySelector('#filter').innerHTML
	document.title = parsedHtml.querySelector('title').innerHTML
	cardContainer.animate(fadeIn, options)
}

export { sortToggle, filterToggle, showSort, hideSort, toggleSort, showFilter, hideFilter, toggleFilter }
