/* eslint-disable */
import axios from 'axios'
import { showAlert } from './alerts'

const login = async (formData) => {
	try {
		const email = formData.get('email')
		const password = formData.get('password')
		const res = await axios({
			method: 'POST',
			url: '/api/v1/users/login',
			data: { email, password },
		})

		if (res.data.status === 'success') {
			showAlert('success', 'Logged in successfully!')
			window.setTimeout(() => location.assign('/'), 1000)
		}
	} catch (error) {
		showAlert('error', error.response.data.message)
		document.querySelector('.forgot-password-button').style.display = 'inline-block'
	}
}

const logout = async () => {
	try {
		const res = await axios({
			method: 'GET',
			url: '/api/v1/users/logout',
		})

		if (res.data.status === 'success') location.reload(true)
	} catch (error) {
		showAlert('error', error.response.data.message)
	}
}

const signup = async (formData) => {
	try {
		const name = formData.get('name')
		const email = formData.get('email')
		const password = formData.get('password')
		const passwordConfirm = formData.get('passwordConfirm')
		const res = await axios({
			method: 'POST',
			url: '/api/v1/users/signup',
			data: { name, email, password, passwordConfirm },
		})

		if (res.data.status === 'success') {
			showAlert('success', 'Signed up successfully!')
			window.setTimeout(() => location.assign('/'), 1000)
		}
	} catch (error) {
		showAlert('error', error.response.data.message)
	}
}

const forgotPassword = async (formData) => {
	try {
		const email = formData.get('email')
		const res = await axios({
			method: 'POST',
			url: '/api/v1/users/forgotPassword',
			data: { email },
		})

		if (res.data.status === 'success') {
			showAlert('success', 'Email gesendet.')
			window.setTimeout(() => location.assign('/'), 1000)
		}
	} catch (error) {
		showAlert('error', error.response.data.message)
	}
}

const resetPassword = async (formData) => {
	const urlParams = new URLSearchParams(window.location.search)
	const token = urlParams.get('token')

	try {
		const password = formData.get('password')
		const passwordConfirm = formData.get('passwordConfirm')
		const res = await axios({
			method: 'PATCH',
			url: `/api/v1/users/resetPassword/${token}`,
			data: { password, passwordConfirm },
		})

		if (res.data.status === 'success') {
			showAlert('success', 'Passwort Zurückgesetzt!')
			window.setTimeout(() => location.assign('/'), 1000)
		}
	} catch (error) {
		showAlert('error', error.response.data.message)
	}
}

export { login, logout, signup, forgotPassword, resetPassword }
