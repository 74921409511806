import pageLoaded from './pageLoaded'

let element
pageLoaded.then(() => {
	element = document.querySelector('#account-menu')
})

const show = (toggleButton) => {
	if (!element || !toggleButton) return
	delete element.dataset.hidden
	toggleButton.ariaExpanded = true
	toggleButton.ariaLabel = toggleButton.dataset.openedLabel
	toggleButton.dataset.pressed = true
}

const hide = (toggleButton) => {
	if (!element || !toggleButton) return
	element.dataset.hidden = true
	toggleButton.ariaExpanded = false
	toggleButton.ariaLabel = toggleButton.dataset.closedLabel
	delete toggleButton.dataset.pressed
}

const toggle = (toggleButton) => {
	if (!element || !toggleButton) return
	if (element.dataset.hidden) {
		show(toggleButton)
	} else {
		hide(toggleButton)
	}
}

export { show, hide, toggle, element }
