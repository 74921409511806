/* eslint-disable */
import axios from 'axios'
import { showAlert } from './alerts'

const updateSettings = async (formData, type) => {
	try {
		const password = formData.get('password')
		const newPassword = formData.get('newPassword')
		const newPasswordConfirm = formData.get('newPasswordConfirm')

		const url = type === 'password' ? '/api/v1/users/updateMyPassword' : '/api/v1/users/updateMe'
		const data = type === 'password' ? { password, newPassword, newPasswordConfirm } : formData

		const res = await axios({ method: 'PATCH', url, data })

		if (res.data.status === 'success') showAlert('success', `Your ${type} has been updated successfully!`)
	} catch (error) {
		showAlert('error', error.response.data.message)
	}
}

export default updateSettings
