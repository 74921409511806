/* eslint-disable */
import 'leaflet'

const displayMap = (locations) => {
	const map = L.map('map', { zoomControl: false, attributionControl: false })
	L.control.zoom({ position: 'bottomright' }).addTo(map)
	const lightTiles = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
		maxZoom: 20,
	})
	const darkTiles = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
		maxZoom: 20,
	})
	if (window.matchMedia('(prefers-color-scheme: light)').matches) {
		lightTiles.addTo(map)
	} else {
		darkTiles.addTo(map)
	}
	window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', ({ matches }) => {
		if (matches) {
			lightTiles.addTo(map)
			darkTiles.remove()
		} else {
			darkTiles.addTo(map)
			lightTiles.remove()
		}
	})

	const greenIcon = L.icon({
		iconUrl: '/pin.png',
		iconSize: [32, 40],
		iconAnchor: [16, 45],
		popupAnchor: [0, -50],
	})

	const points = []
	locations.forEach((loc) => {
		points.push([loc.coordinates[1], loc.coordinates[0]])

		let markerLabel
		if (loc.day) {
			markerLabel = `<p>Day ${loc.day}: ${loc.description}</p>`
		} else {
			markerLabel = `<p>Start: ${loc.description}</p>`
		}

		L.marker([loc.coordinates[1], loc.coordinates[0]], { icon: greenIcon })
			.addTo(map)
			.bindPopup(markerLabel, {
				autoClose: false,
				className: 'mapPopup',
			})
			.openPopup()
	})
	L.polyline(points, { color: 'var(--text)', opacity: 0.8, weight: '3', dashArray: '10, 10', dashOffset: '10' }).addTo(map)

	const bounds = L.latLngBounds(points).pad(0.5)
	map.fitBounds(bounds)

	map.scrollWheelZoom.disable()
}

export default displayMap
