/* eslint-disable */
import axios from 'axios'
import { showAlert } from './alerts'

const bookTour = async (tourId) => {
	try {
		const res = await axios(`/api/v1/bookings/checkout-session/${tourId}`)
		location.assign(res.data.checkoutUrl)
	} catch (error) {
		showAlert('error', error)
	}
}

export { bookTour }
